import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { flowRight, isEqual, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import {
  getCategoryPath,
  getFeedTitle,
  getCategoriesSlice,
} from '@wix/communities-blog-client-common';

import withAuth from '../../hoc/with-auth';
import withTranslate from '../../hoc/with-translate';
import {
  getCurrentMatchPathname,
  getRouteParams,
} from '../../router/router-selectors';
import { getLastUpdatedDate } from '../../selectors/app-settings-selectors';
import { getViewerAllPostsLabelTranslation } from '../../selectors/viewer-all-post-label-translation-selectors';
import { getHeaderLinks } from '../../services/get-header-links';
import { isSeo, isSSR } from '../../store/basic-params/basic-params-selectors';
import { getSearchInputWidth } from '../../store/search-input/search-input-selectors';
import AccessibilityAlert from '../accessibility-alert/accessibility-alert';
import Link from '../link/internal-link';
import MoreCategoriesDropdown from '../more-categories-dropdown';
import { connect } from '../runtime-context';
import { calculateHeaderItems } from './calculate-header-items';
import styles from './header-navigation.scss';

const LINK_PADDING = 40;

export class HeaderNavigation extends PureComponent {
  state = {
    visibleCategories: this.props.categories,
    accessibilityAlert: null,
    moreCategories: [],
  };

  componentDidMount() {
    this.updateVisibleCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const propsKeys = [
      'isAuthenticated',
      'searchInputWidth',
      'viewMode',
      'settingsUpdated',
      'hostWidth',
    ];
    const stateKeys = ['visibleCategories', 'moreCategories'];
    const propsChanged = propsKeys.some(
      (key) => !isEqual(prevProps[key], this.props[key]),
    );
    const stateChanged = stateKeys.some(
      (key) => !isEqual(prevState[key], this.state[key]),
    );
    if (propsChanged) {
      this.setState(
        { visibleCategories: this.props.categories, moreCategories: [] },
        this.updateVisibleCategories,
      );
    }
    if (stateChanged) {
      this.updateVisibleCategories();
    }
  }

  onNavigation = (title) => () =>
    this.showAccessibilityAlert(
      this.props.t('header.accessibility-alert', {
        title,
        count: this.props.categories.length,
      }),
    );

  renderLink = ({ path, text, key, onNavigation }, index, linkArray) => {
    const isActive = this.props.currentPathDecoded === path;

    const linkClassNames = classNames(
      styles.link,
      'blog-navigation-container-color blog-navigation-container-font ',
      'blog-navigation-link-hover-color',
      isActive && 'blog-navigation-link-active-color',
      ...(index === 0 || index === linkArray.length - 1
        ? ['has-custom-focus', styles.linkAccessibility]
        : []),
    );

    return (
      <li key={key} data-hook={`header-navigation__${path}`}>
        <Link
          className={linkClassNames}
          to={path}
          addHoverClasses={!true}
          navigateWithoutScroll={!this.props.isSSR && !this.props.isSEO}
          isActive={isActive}
          onNavigation={onNavigation}
        >
          {text}
        </Link>
      </li>
    );
  };

  shouldRenderMoreButton = (categories) => {
    if (typeof window === 'undefined') {
      return false;
    }
    if (categories.length > 0) {
      return true;
    }
    const topNavigationContainer = document.querySelector(
      '.blog-header__navigation',
    );
    if (!topNavigationContainer) {
      return false;
    }
    const maxWidth = topNavigationContainer.getBoundingClientRect().width;
    const renderedItemsWidth = Array.from(
      topNavigationContainer.children,
    ).reduce(
      (acc, link, index) =>
        acc +
        link.getBoundingClientRect().width +
        (index === 0 ? 0 : LINK_PADDING),
      0,
    );
    return maxWidth < renderedItemsWidth;
  };

  renderMoreButton = (categories, width) => {
    if (!this.shouldRenderMoreButton(categories)) {
      return null;
    }
    const { t, currentPath } = this.props;
    const links = this.addNavigationToHeaderLinks(categories);
    links.shift();

    return (
      categories && (
        <li id="categories-more-button">
          <MoreCategoriesDropdown
            currentPath={currentPath}
            items={links}
            width={width}
            moreText={t('header.more-button')}
            className="blog-navigation-container-font blog-navigation-link-hover-fill blog-navigation-link-hover-color blog-navigation-container-color blog-navigation-container-fill"
            hoverClassName="blog-navigation-container-hover-color blog-navigation-container-hover-fill"
            activeClassName="blog-navigation-link-active-color blog-navigation-container-active-color blog-navigation-container-active-fill"
            onNavigation={this.showAccessibilityAlert}
          />
        </li>
      )
    );
  };

  addNavigationToHeaderLinks = (categories) => {
    const { feedTitle, categoryPath } = this.props;
    return getHeaderLinks({ categories, feedTitle, categoryPath }).map((i) => ({
      ...i,
      onNavigation: this.onNavigation(i.text),
    }));
  };

  renderAccessibilityAlert = () => {
    return <AccessibilityAlert message={this.state.accessibilityAlert} />;
  };

  showAccessibilityAlert = (alert) => {
    this.setState({ accessibilityAlert: alert });
  };

  updateVisibleCategories() {
    const { categories } = this.props;
    const nextState = calculateHeaderItems(
      categories,
      this.state.visibleCategories,
      LINK_PADDING,
    );
    !isEmpty(nextState) && this.setState(nextState);
  }

  render() {
    const { visibleCategories, moreCategories, moreCategoriesMenuWidth } =
      this.state;
    const links = this.addNavigationToHeaderLinks(visibleCategories);
    return (
      <nav
        className={classNames(
          styles.topNavigation,
          'blog-header__navigation',
          'blog-navigation-container-font',
        )}
        ref={this.setTopNavigationContainer}
        aria-label="blog"
      >
        {this.state.accessibilityAlert && this.renderAccessibilityAlert()}
        <ul className={styles.container}>
          {links.map(this.renderLink)}
          {this.renderMoreButton(moreCategories, moreCategoriesMenuWidth)}
        </ul>
      </nav>
    );
  }
}

HeaderNavigation.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  currentPath: PropTypes.string.isRequired,
  currentPathDecoded: PropTypes.string.isRequired,
  searchInputWidth: PropTypes.number,
  appData: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  viewMode: PropTypes.string.isRequired,
  settingsUpdated: PropTypes.number,
  categoryPath: PropTypes.string,
  isSSR: PropTypes.bool.isRequired,
  isSEO: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps, actions, host) => {
  const { page } = getRouteParams(state) || {};
  const currentMatchPathname = getCurrentMatchPathname(state);
  const currentPath = page
    ? currentMatchPathname.replace(`/page/${page}`, '')
    : currentMatchPathname;

  return {
    categories: getCategoriesSlice(state, 30),
    currentPath,
    hostWidth: host.dimensions.width,
    currentPathDecoded: decodeURIComponent(currentPath),
    searchInputWidth: getSearchInputWidth(state),
    feedTitle: getViewerAllPostsLabelTranslation(state) || getFeedTitle(state),
    viewMode: state.viewMode,
    settingsUpdated: getLastUpdatedDate(state),
    categoryPath: getCategoryPath(state),
    isSSR: isSSR(state),
    isSEO: isSeo(state),
  };
};

export default flowRight(
  connect(mapStateToProps),
  withAuth,
  withTranslate,
)(HeaderNavigation);
